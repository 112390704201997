<template>
  <div id="DayGallery">
    <app-navbar></app-navbar>
    <div class="whiteBallone">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/whiteBalloneHorizont.png" alt="whiteBallone">
    </div>
    <div class="redBalloneHalfVertical">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/redBalloneHalfVertical.png" alt="whiteBallone">
    </div>
    <div class="container pb-lg-4">
      <h1 class="text-center">{{ album.name | translation }}</h1>
      <div class="row no-gutters">
        <div v-for="(image, index) in images" v-bind:key="index" class="col-lg-3 imgYear">
          <img loading="lazy" v-on:click="$refs.galleryModal.show(image)" class="img-fluid cropped-img gallery-picture" :src="image"
               alt=""></div>
      </div>
      <gallery-modal ref="galleryModal"/>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import axios from "@/api/axiom-instance";
import GalleryModal from "@/components/base/GalleryModal";
import config from "@/config";

export default {
  name: "DaysGallery",
  components: {
    'app-navbar': navbar,
    'app-footer': footer,
    'gallery-modal': GalleryModal
  },
  data() {
    return {
      album: {},
      images: [],
      galleryUrl: null
    }
  },
  created() {
    axios
        .get("/image/list", {params: {type: "album-others/" + this.$route.params.id}})
        .then((success) => {
          this.images = success.data.map(it => config.API_URL + "/image/preview?type=" + it.type + "&name=" + it.name)
        }).catch(error => this.error = error)
    axios
        .get("/album", {params: {id: this.$route.params.id}})
        .then((success) => {
          this.album = success.data;
        }).catch(error => this.error = error)


  }
}
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  padding-top: 3em;
  padding-bottom: 1.5em;
  font-family: "Transcript Pro";
}

h2 {
  color: black;
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h4 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  font-size: 2em;
  font-family: 'Transcript Pro';
  position: absolute;
  bottom: 0%;
}

.gallery-picture:hover {
  box-shadow: 0 3px 8px 0 #1d212470;
}

.imgYear {
  color: #eae7d6;
}

.imgYear:hover {
  color: white;
}

#DayGallery {
  background-color: #FFCE6D;
  min-height: 100vh;
}

.cropped-img {
  height: 300px;
  object-fit: cover;
}

.years {
  padding-bottom: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-family: "Transcript Pro";
}

.years a {
  padding: 5px 20px;
  color: #eae7d6;
}

.years a:hover {
  color: white;
}
.whiteBallone {
  position: absolute;
  width: 10%;
  top: 20vh;
  left: 5%;
}
.redBalloneHalfVertical {
  position: absolute;
  width: 6%;
  top: 100vh;
  text-align: end;
  right: 0%;
}
@media (max-width: 1000px){
  .whiteBallone,
  .redBalloneHalfVertical {
    display: none;
  }
}
</style>
